import React, { useState, useEffect } from 'react';
import CustomReport from './CustomReport';
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import emailjs from 'emailjs-com';
import styled from 'styled-components';  // Assuming you're using styled-components
import tw from 'twin.macro';  // Assuming Tailwind is set up

const secretKey = "imareesha1412hosiyarinhinhihopayegatmse14";
const decryptVin = (encryptedVin) => {
  const base64Ciphertext = encryptedVin.replace(/-/g, '+').replace(/_/g, '/');
  const ciphertext = atob(base64Ciphertext);
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
// Loader components
const LoaderContainer = styled.div`
  ${tw`flex flex-col items-center justify-center h-screen`}
`;

const CircularLoader = styled.div`
  ${tw`animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-primary-500`}
`;

const LoadingText = styled.p`
  ${tw`mt-4 text-lg font-semibold text-primary-500`}
`;

const VinReport = () => {
  const [report, setReport] = useState(null);
  const [error, setError] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const { vin } = useParams(); 
  let decryptedVinNumber;

  const fetchVinReport = async () => {
    try {
      setLoading(true); // Show loader when fetching the report
      const vincheck = localStorage.getItem("vin");
      decryptedVinNumber = decryptVin(vin);

      if (decryptedVinNumber === vincheck || true) {
        const authResponse = await fetch('https://server-vin-eight.vercel.app/api/auth', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
            body: 'emailAddress=ahmed12.gdg@gmail.com&password=Ahmed12.'
        });

        if (!authResponse.ok) {
          throw new Error('Failed to authenticate');
        }

        const authData = await authResponse.json();
        const accessToken = authData.accessToken;

        const vinResponse = await fetch('https://server-vin-eight.vercel.app/api/vin-report', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({ vin: decryptedVinNumber })
        });

        if (!vinResponse.ok) {
          throw new Error('Failed to fetch VIN report');
        }

        const vinData = await vinResponse.json();
        setReport(vinData);
        setError(null);
        if(decryptedVinNumber === vincheck){
          await generatePDF();
        }
      } else {
        alert("Don't try to crack our website");
      }
    } catch (error) {
      setError(error.message);
      setReport(null);
    } finally {
      setLoading(false); // Hide loader after fetching
    }
  };

  const generatePDF = async () => {
    setLoading(true);
    try {
      const currentUrl = window.location.href;
      const VinNumber = decryptVin(vin);

      const response = await fetch('https://areesha1416-github-io.vercel.app/convert-to-pdf', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url: currentUrl, vinnumber: VinNumber}),
        mode: 'no-cors', // Use no-cors mode
      });

      const data = await response.json();

      if (response.ok) {
        const link = document.createElement('a');
        link.href = data.url;
        link.download = `My Vehicle Reports - Vin Number: ${decryptedVinNumber}.pdf`; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setPdfUrl(data.url);

        await sendEmail(data.url, true);
      } else {
        console.error('Error generating PDF:', data.error);
        await sendEmail(null, false, data.error);
      }
    } catch (error) {
      console.error('Failed to generate PDF:', error);
      await sendEmail(null, false, error.message);
    } finally {
      setLoading(false);
    }
  }

 
  const sendEmail = async (pdfUrl, success, errorMessage = '') => {
    try {
      const planName = localStorage.getItem("planName");
      const planPrice = localStorage.getItem("planPrice");
      const userEmail = localStorage.getItem("userEmail");
  
      // Fetch IP Address
      const ipResponse = await axios.get('https://api.ipify.org?format=json');
      const userIP = ipResponse.data.ip || 'Unable to get IP address due to privacy policy';
  
      // Prepare the email template parameters with the link to the hosted PDF
      const templateParams = {
        to_name: 'Ahmed',
        to_email: userEmail,  // Send to the user's email
        vin_number: decryptedVinNumber,
        report: pdfUrl ? `${pdfUrl}` : `${window.location.href}`,
        ip_address: userIP,
        user_email: userEmail,
        plan_name: planName,
        plan_price: planPrice,
        status: success ? 'Report generated successfully' : 'Report generated successfully',
        reason: success ? '' : ``
      };
  
      // Send the email via EmailJS to both the admin and the user
      const adminEmail = 'info@myvehiclereports.com'; // Replace with the actual admin email
      await emailjs.send('service_bhgapop', 'template_43reryc', { ...templateParams, to_email: adminEmail }, 'lUUCO2gqKm4UwoFwC');
      await emailjs.send('service_bhgapop', 'template_43reryc', templateParams, 'lUUCO2gqKm4UwoFwC');
      
      console.log('Email successfully sent!');
    } catch (error) {
      console.error('Error fetching IP or sending email:', error);
    }
  };
  
  
  
  
  useEffect(() => {
    fetchVinReport();
  }, []);

  return (
    <div>
    {loading ? ( // Show loader while loading
      <LoaderContainer>
        <CircularLoader />
        <LoadingText>Loading...</LoadingText>
      </LoaderContainer>
    ) : error ? (
      <p>Error: {error}</p>
    ) : report && (
      <>
        {pdfUrl && (
          <button
            className="btn btn-primary"
            style={{ 
              fontSize: '20px', 
              padding: '10px 20px', 
              marginTop: '10px', 
              marginLeft: 'auto', 
              marginRight: '80px', 
              display: 'block' 
            }}
            onClick={() => {
              const link = document.createElement('a');
              link.href = pdfUrl;
              link.download = `My Vehicle Reports - Vin Number: ${decryptedVinNumber}.pdf`; 
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
          >
            Download PDF
          </button>
        )}
        <CustomReport vehicleData={report} />
      </>
    )}
  </div>
  );
};

export default VinReport;
