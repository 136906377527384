import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/check-circle.svg";
import { FaCar } from "react-icons/fa";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { IoLogoModelS } from "react-icons/io";
import { CiBarcode } from "react-icons/ci";
import { GiCalendarHalfYear } from "react-icons/gi";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useParams } from 'react-router-dom';
import { HiBadgeCheck } from "react-icons/hi";
import { ImCross } from "react-icons/im";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";



import CryptoJS from 'crypto-js';
const stripePromise = loadStripe("pk_live_51QhL4JDt503nKIaWod4B3kZWrfIXAdW7BrCCmWO3nKsIWNezcnteHAZ9woq3gUNRqOM7uoFIvqY5MuEfv5uW3s3C00BtjdwY6P"); // Replace with your Stripe Publishable Key
const secretKey = "imareesha1412hosiyarinhinhihopayegatmse14"; // Replace this with your secure key
const secretKey1 = "imareesha1412hosiyarinhinhihopayegatmse14"; // Replace this with your secure key
//const secretKey1 = "hosiyarinhinhihopayegatmse14imareesha1412"; // Replace this with your secure key
const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV for each encryption


const getEncryptionKey = (plan) => {
  switch (plan) {
    case 'Basic Plan':
      return secretKey;
    case 'Advanced Plan':
      return secretKey1;
    default:
      throw new Error('Invalid plan');
  }
};

const encryptVin = (vin, plan) => {
  const key = getEncryptionKey(plan);
  const ciphertext = CryptoJS.AES.encrypt(vin, key).toString();
  const base64Ciphertext = btoa(ciphertext).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  return base64Ciphertext;
};

const ModalBackground = styled.div`
  ${tw`fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75`};
  backdrop-filter: blur(5px); /* Adds a slight blur to the background */
`;

const ModalContent = styled.div`
  ${tw`bg-white p-8 rounded-lg shadow-lg`};
  width: 90%;
  max-width: 500px;
`;

const CloseButton = styled.button`
  ${tw`absolute top-0 right-0 mt-4 mr-4 text-gray-500`};
  width: 40px;  // Button ka width increase
  height: 40px; // Button ka height increase
  font-size: 24px; // Text ya icon size increase
  &:hover {
    ${tw`text-gray-700`};
  }
`;


const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-0 md:py-0 items-center`;

const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const DecoratorBlob = tw(
  SvgDotPattern
)`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`;
const LoaderContainer = styled.div`
  ${tw`flex flex-col items-center justify-center h-screen`}
`;

const CircularLoader = styled.div`
  ${tw`animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-primary-500`}
`;

const LoadingText = styled.p`
  ${tw`mt-4 text-lg font-semibold text-primary-500`}
`;
const EmailContainer = tw.div`shadow-lg p-6 bg-white mx-auto w-full md:w-10/12 h-48 mt-12  items-center justify-center`; // Centering content
const EmailLabel = tw.label`mt-10 block text-gray-700 font-semibold mb-2 w-full`;
const EmailInput = tw.input`border border-gray-300 bg-gray-100 p-4 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-lg`; // Square corners and background color
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Heading2 = tw.div`text-center text-2xl sm:text-2xl lg:text-2xl leading-tight flex items-center justify-center`;

const Description = tw.p`mt-8 text-center md:text-left text-xs md:text-base lg:text-sm font-medium leading-relaxed text-secondary-100 lg:p-0 p-6`;

const Features = styled.div`
  ${tw`mx-auto md:mx-0 grid grid-cols-1 md:grid-cols-3 gap-8`}
`;
const Feature = tw.div`mt-10 lg:mt-8 flex items-center md:items-start flex-col md:mr-8 last:mr-0`;

const FeatureHeadingContainer = tw.div`flex items-center`;
const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-primary-500 text-center rounded p-2 flex-shrink-0`}
  ${props => [
    props.iconRoundedFull && tw`rounded-full`,
    props.iconFilled && tw`border-0 bg-primary-500 text-gray-100`
  ]}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const FeatureHeading = tw.div`ml-3 font-bold text-xl`;
const PayHeading = tw.div`font-bold text-xl text-primary-500`;

const FeatureDescription = tw.div`mt-4 text-center md:text-left text-gray-600 leading-relaxed`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-start relative`;
const Plan = styled.div`
  ${tw`w-full max-w-screen-xl mt-16 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`},
    
  ${props =>
    props.featured &&
    css`
      ${tw`border-2 border-gray-200 shadow-none`}
    `}
`;
const Icon = styled.div`
  ${tw`lg:mr-4 md:mr-2`} // Add some margin to the right of the icon
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-8 -mx-8 bg-gray-100 rounded-t-lg`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 first:mt-0 font-semibold text-gray-500`}
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ConfirmButton = styled(PrimaryButtonBase)`
  ${tw`tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

const StatusContainer = styled.div`
  ${tw`w-full p-8 rounded-lg text-center text-white`}
  background: ${props => props.success
    ? 'linear-gradient(135deg, rgba(10,88,90,0.6) 0%, rgba(129,93,22,0.6) 100%)'
    : 'linear-gradient(135deg, rgba(139,0,0,0.6) 0%, rgba(139,69,19,0.6) 100%)'};
  backdrop-filter: blur(10px); // For the glassy effect
  border: 1px solid rgba(255, 255, 255, 0.18); // Optional: Add a border to enhance the glass effect
`;



const PaymentForm = ({ amount, VinNumber, plan, emailAddress, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const handlePayment = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      setError("Stripe has not loaded yet. Please wait.");
      return;
    }

    setError("");
    setIsProcessing(true);

    try {
      // Step 1: Fetch client secret from the backend
      const { data } = await axios.post("https://areesha1416-github-io.vercel.app/api/create-payment-intent", {
        amount: parseInt(amount) * 100, // Convert amount to cents
        email: emailAddress,
        vinNumber: VinNumber,
        plan,
      });

      const clientSecret = data.clientSecret;

      // Step 2: Confirm the payment
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: { email: emailAddress },
        },
      });

      if (stripeError) {
        setError(stripeError.message);
        setIsProcessing(false);
        return;
      }

      if (paymentIntent && paymentIntent.status === "succeeded") {
        const encryptedVinNumber = encryptVin(VinNumber, plan);
        localStorage.setItem('vin', VinNumber);
        localStorage.setItem('planName', plan);
        localStorage.setItem('planPrice', amount);
        localStorage.setItem('userEmail', emailAddress);
        onSuccess(encryptedVinNumber);
        window.location.href = `/completevinreport/${encryptedVinNumber}`;
      }
    } catch (backendError) {
      setError("Error processing payment. Please try again later.");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handlePayment} className="max-w-md p-6 bg-white shadow-md rounded-md text-left">
      <h2 tw="text-xl font-semibold mb-4">Make a Payment</h2>
      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-500">{success}</p>}
      <div className="mb-4">
        <label tw="block text-sm font-medium text-gray-700 mb-2">Card Details</label>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#32325d",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#fa755a",
              },
            },
          }}
        />
      </div>
      <button
        type="submit"
        tw="mt-4 w-full bg-primary-500 text-white py-2 px-4 rounded-lg hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2 disabled:opacity-50"
        disabled={!stripe || isProcessing}
      >
        {isProcessing ? "Processing..." : `Pay $${amount}`}
      </button>
    </form>
  );
};




export default ({
  subheading = "Vehicle Information",
  heading = (
    <>
      Car Details <span tw="text-primary-500">By VIN Lookup</span>
    </>
  ),
  description = "This report contains detailed information about the car, including ownership history, which provides a comprehensive overview of previous owners and any changes in registration. It also includes vehicle specifications, such as engine type, transmission, and fuel efficiency, alongside safety recalls that outline any issued recalls and their resolution status. Additionally, the report covers accident history, detailing any reported incidents or damages, as well as mileage records to identify any discrepancies. Service and maintenance records are included to highlight any performed maintenance, and it offers an estimate of the current market value based on the vehicle's condition and history, along with information about optional features and upgrades.",
  primaryButtonText = "Learn More",
  primaryButtonUrl = "",
  imageSrc = TeamIllustrationSrc,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  showDecoratorBlob = false,
  textOnLeft = true,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null,
  plans = null,
  primaryButtonText1 = "Buy Now",
  planDurations = [
    {
      text: "Month",
      switcherText: "Monthly",
    },
    {
      text: "Year",
      switcherText: "Yearly",
    }
  ]

}) => {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const vehicle = data;
  const { id } = useParams(); // Assuming your route is like /vinreport/:vin

  // Use the extracted VIN number
  const carVinNumber = id; // This will hold the VIN from the URL

  // Email validation function
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailAddress, setEmailAddress] = useState(''); // Define emailAddress state variable

  const handleEmailChange = useCallback((e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    setIsEmailValid(validateEmail(inputValue));
    setEmailAddress(inputValue); // Set emailAddress to the current value of email
  }, [validateEmail]);

  useEffect(() => {
    setEmailAddress(email); // Update emailAddress state variable when email state changes
  }, [email]);

  const [showPayPalButtons, setShowPayPalButtons] = useState(false);
  const [finalAmount, setFinalAmount] = useState(null);
  const [finalPlan, setFinalPlan] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);


  const handleBuyNowClick = (finalAmount, finalPlan) => {
    if (!isEmailValid) {
      alert("Please enter a valid email address first.");
      return;
    }
  
    // Set the final amount and plan
    setFinalAmount(finalAmount);
    setFinalPlan(finalPlan);
  
    // Now show PayPal buttons
    setShowPayPalButtons(true);
    openModal();
  };
  

  const defaultFeatures = [
    {
      Icon: BriefcaseIcon,
      title: "VIN",
      description: carVinNumber || "Not Found",
      iconContainerCss: tw`bg-teal-300 text-teal-800`
    },
    {
      Icon: FaCar,
      title: "Make",
      description: vehicle?.make || "Not Found",
      iconContainerCss: tw`bg-green-300 text-green-800`
    },
    {
      Icon: IoLogoModelS,
      title: "Model",
      description: vehicle?.model || "Not Found",
      iconContainerCss: tw`bg-blue-300 text-blue-800`
    },
    {
      Icon: GiCalendarHalfYear,
      title: "Year",
      description: vehicle?.year ? vehicle.year.toString() : "Not Found",
      iconContainerCss: tw`bg-orange-300 text-orange-800`
    }
  ];

  if (!features) features = defaultFeatures;

  const defaultPlans = [
    {
      name: "Basic Plan",
      durationPrices: ["$49.99", "$0"],
      mainFeature: "For Get Detailed Report",
      features: ["Vehicle specifications", "Vehicle Record", "History Record", "Auto Specs", "", "", "", "", "", "", "", "", ""],
    },
    {
      name: "Advanced Plan",
      durationPrices: ["$94.99", "$0"],
      mainFeature: "For Get Detailed Report with Auction",
      features: ["Vehicle specifications", "Vehicle Record", "History Record", "Junk / Salvage / Insurance records", "Auto Specs", "Location History", "Consumer Complaints", "NHTSA Recalls"],
    }
  ];

  if (!plans) plans = defaultPlans;
  const [activeDurationIndex, setActiveDurationIndex] = useState(0);
  const [buttonText, setButtonText] = useState(primaryButtonText);

  const paypalScriptOptions = {
    "client-id": "Ab8-5PHYTgFj3xkRts-pOA1oclDFEnroJnY2D276RdyaOAX2PiTp93vc7GJSwzc_cilPO-HQ7tWB9N5f",
    currency: "USD"
  };



  const apiKey = "I2ztBvX9tSNnLxgaDfeNtVu20shsWmmS"; // Replace with your actual API key
  //const apiKey = "123";
  //const apiKey = "I00ph"; // Replace with your actual API key
  useEffect(() => {
    const fetchVINData = async () => {
      try {
        const response = await axios.get(`https://mc-api.marketcheck.com//v2/decode/car/${carVinNumber}/specs?api_key=${apiKey}`);
        setData(response.data);
        setStatus({ success: true });
        console.log(response.data);

      } catch (error) {
        setStatus({ success: false });
        console.error("Error fetching VIN data:", error);
      } finally {
        setIsLoading(false); // Stop loading when the request is finished
      }
    };

    fetchVINData();
  }, [carVinNumber, apiKey]);

  if (isLoading) {
    return (
      <LoaderContainer>
        <CircularLoader />
        <LoadingText>Loading...</LoadingText>
      </LoaderContainer>
    );
  }
  const handleSuccess = (encryptedVinNumber) => {
    // Handle redirection based on plan type
    // if (plan === "Basic Plan" || plan === "Advanced Plan") {
    //   window.location.href = `/completevinreport/${encryptedVinNumber}`;
    // } else {
    //   alert("Invalid Plan");
    // }
  };


  return (
    <PayPalScriptProvider options={paypalScriptOptions}>
      <Container>
        {status !== null && (
          <StatusContainer success={status.success}>
            {status.success ? (
              <>

                <Heading2>
                  <Icon>
                    <HiBadgeCheck size={60} />
                  </Icon>
                  Successfully  Retrieved VIN Number: {carVinNumber}
                </Heading2>
              </>
            ) : (
              <>

                <Heading2> <Icon> <ImCross size={40} /> </Icon> Incorrect VIN Number: {carVinNumber}</Heading2>
              </>
            )}
          </StatusContainer>
        )}
        <TwoColumn>

          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              <Subheading>{subheading}</Subheading>
              <Heading>{heading}</Heading>

              <Features>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureHeadingContainer>
                      <FeatureIconContainer
                        iconFilled={iconFilled}
                        iconRoundedFull={iconRoundedFull}
                        css={feature.iconContainerCss || iconContainerCss}
                      >
                        {<feature.Icon />}
                      </FeatureIconContainer>
                      <FeatureHeading>{feature.title}</FeatureHeading>
                    </FeatureHeadingContainer>
                    <FeatureDescription>{feature.description}</FeatureDescription>
                  </Feature>
                ))}
              </Features>
              <Description>{description}</Description>

              {/* <PrimaryButton buttonRounded={buttonRounded} onClick={handleLearnMoreClick} as="a">
                {buttonText}
              </PrimaryButton> */}
            </TextContent>
          </TextColumn>

          <ImageColumn>
            {/* New Email Container */}
            <EmailContainer>
              <EmailLabel>Enter your Email</EmailLabel>
              <EmailInput
                type="email"
                placeholder="Info@myvehiclereports.com"
                value={email} // Bind the email state to the input value
                onChange={handleEmailChange} // Handle email input changes
              />
              {!isEmailValid && email.length > 0 && ( // Show an error message if the email is invalid
                <p style={{ color: "red", fontSize: "12px" }}>Valid email required</p>
              )}
            </EmailContainer>
            <PlansContainer>
              {plans.map((plan, index) => (
                <Plan key={index} featured={plan.featured}>
                  <PlanHeader>
                    <span className="name">{plan.name}</span>
                    <span className="priceAndDuration">
                    <span className="price">{plan.durationPrices[activeDurationIndex]}</span>
                    </span>

                    <span className="mainFeature">{plan.mainFeature}</span>
                  </PlanHeader>
                  <PlanFeatures>
                    {plan.features.map((feature, index) => (
                      <span key={index} className="feature">
                        {feature}
                      </span>
                    ))}
                  </PlanFeatures>
                  <PlanAction>
                  <BuyNowButton onClick={() => handleBuyNowClick(plan.durationPrices[activeDurationIndex].replace("$", ""), plan.name)}>
                    Buy Now
                  </BuyNowButton>


                  </PlanAction>
                </Plan>
              ))}
            </PlansContainer>
          </ImageColumn>
        </TwoColumn>
        {isModalOpen && (
        <ModalBackground>
          <ModalContent>
            
            <CloseButton onClick={closeModal}><ImCross/></CloseButton>
            <Elements stripe={stripePromise}>
      <PaymentForm
          amount={finalAmount}
          VinNumber={carVinNumber}
          plan={finalPlan}
          emailAddress={emailAddress}
          onSuccess={handleSuccess}
      />
    </Elements>
        
          </ModalContent>
        </ModalBackground>
      )}
      </Container>
    </PayPalScriptProvider>
  );
};
